.App {
  margin: 0 auto;
  max-width: 1024px;
  padding: 0 1.2em 1.2em 1.2em;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
}
