html {
  background-size: 100%;
  font-size: 0.92rem;
}

body {
  color: #342d33;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  letter-spacing: 0.03em;
  line-height: 1.5;
  margin: 0;
  margin-top: 50px;
  text-rendering: optimizeLegibility;
}

.App {
  margin: 0 auto;
  max-width: 1024px;
  padding: 0 1.2em 1.2em 1.2em;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
}

