html {
  background-size: 100%;
  font-size: 0.92rem;
}

body {
  color: #342d33;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  letter-spacing: 0.03em;
  line-height: 1.5;
  margin: 0;
  margin-top: 50px;
  text-rendering: optimizeLegibility;
}
